<template>
  <gf-card>
    <el-form ref="sm" :model="sm" label-width="110px">
      <gf-content v-loading="loading">
        <template #title>
          <span v-if="mode === 'create'" class="font-weight-bold">New Shipment</span>
          <span v-else>{{ title }}</span>
        </template>
        <template #header>
          <el-row>
            <el-col :lg="12" :md="12" :sm="24">
              <el-form-item label="Bill To" label-width="100px" prop="bill_to_id">
                <span>{{ sm.bill_to.label }}</span>
              </el-form-item>
              <el-form-item label="Ship To" label-width="100px" prop="ship_to_id">
                <span>{{ sm.ship_to.label }}</span>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="12" :sm="24">
              <el-form-item label="Packed" label-width="100px">
                <el-date-picker v-model="sm.packed" format="dd MMM yyyy" size="small" style="width: 60%;" type="date" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <div class="table">
          <gf-table :data="sm.items" :scrollable="false">
            <el-table-column label="Item Name" min-width="200" prop="name">
              <template slot-scope="slot">
                <div class="gf-item">
                  <div class="gf-item__name">
                    <span v-if="slot.row.product.name === slot.row.variant.name && slot.row.variant.is_pack === 0">{{ slot.row.variant.name }}</span>
                    <span v-else-if="slot.row.variant.is_pack === 0">{{ slot.row.product.name }}  - {{ slot.row.variant.name }}</span>
                    <span v-else>
                    <span v-if="slot.row.product.name === slot.row.variant.variant.name">{{ slot.row.variant.variant.name }} - {{ slot.row.variant.name }}</span>
                    <span v-else>{{ slot.row.product.name }} - {{ slot.row.variant.variant.name }} - {{ slot.row.variant.name }}</span>
                    <span v-if="slot.row.variant.is_pack === 1"> (Pack of {{ slot.row.variant.pack_size }})</span>
                  </span>
                  </div>
                  <div class="gf-item__sub">
                    <span class="gf-item__sub__sku">SKU: {{ slot.row.variant.sku }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Qty Packed" min-width="120">
              <template slot-scope="slot">
                <el-form-item :prop="'items.' + slot.$index + '.quantity'" :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                  <el-input v-model="slot.row.quantity" size="mini" style="width: 100%"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Not Packed" min-width="120">
              <template  slot-scope="slot">
                <el-input :value="parseFloat(slot.row.total_quantity) - parseFloat(slot.row.quantity) - parseFloat(slot.row.order_item.packed)" readonly size="mini" style="width: 100%"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Total Qty" min-width="120">
              <template slot-scope="slot">
                <el-form-item label-width="0">
                  <el-input v-model="slot.row.total_quantity" readonly size="mini" style="width: 100%"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Total" min-width="120">
              <template  slot-scope="slot">
                <el-input :value="$NumberFormat(slot.row.cost * slot.row.quantity - (slot.row.cost * slot.row.quantity * (slot.row.discount / 100)))" readonly size="mini" style="width: 100%"></el-input>
              </template>
            </el-table-column>
          </gf-table>
        </div>
        <div class="footer mt-4">
          <div class="note">
            <el-input v-model="sm.message" :rows="2" placeholder="Message to Customer" type="textarea"></el-input>
          </div>
          <div class="gf-summary">
            <ul class="summary">
              <li class="summary__item">
                <span class="text">Total Units</span>
                <span class="value">{{ $NumberFormat(totalUnits) }}</span>
              </li>
              <li v-if="sm.is_inclusive !== 2" class="summary__item">
                <span class="text">Subtotal</span>
                <span class="value">{{ $NumberFormat(subtotal) }}</span>
              </li>
              <div v-if="sm.is_inclusive !== 2">
                <li v-for="(vat, index) in vats" :key="index" class="summary__item">
                  <span class="text">{{ (sm.is_inclusive) ? 'Including': 'Plus' }} VAT ({{ vat.rate }}%)</span>
                  <span class="value">{{ $NumberFormat(vat.value) }}</span>
                </li>
              </div>
              <li class="summary__item">
                <span class="text">Total</span>
                <span class="value">{{ $NumberFormat(total) }}</span>
              </li>
            </ul>
          </div>
        </div>
        <template #footer>
          <div class="text-right">
            <gf-button class="mr-3" size="sm" type="primary" @click="submit">Submit</gf-button>
            <gf-button size="sm" type="secondary" @click="cancel">Cancel</gf-button>
          </div>
        </template>
      </gf-content>
    </el-form>
  </gf-card>
</template>

<style lang="scss" scoped>
.content {
  .el-form-item  {
    width: auto;
    margin: 0;
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  .note {
    width: 500px;
  }
}
</style>

<script>
// Services
import SalesOrderService from '@/services/v1/SalesOrder'
import SalesShipmentService from '@/services/v1/SalesShipment'

export default {
  props: {
    title: String,
    updateChild: Boolean
  },
  data () {
    return {
      loading: false,

      so_number: null,
      sm: {
        customer_id: null,
        bill_to_id: null,
        bill_to: {
          label: null
        },
        ship_to_id: null,
        ship_to: {
          label: null
        },
        ship_from_id: null,
        ship_from: {
          name: null
        },
        packed: this.$DateISOFormat(new Date().toISOString()),
        message: null,
        is_inclusive: 0,
        items: [],
        vats: [],
        total: null,
        subtotal: null
      },

      // helpers
      mode: 'create',

      // Services
      soService: null
    }
  },
  computed: {
    totalUnits () {
      let total = 0

      for (let i = 0; i < this.sm.items.length; i++) {
        const e = this.sm.items[i]
        total += parseFloat(e.quantity)
      }

      return total
    },
    subtotal () {
      let total = 0

      if (this.sm.is_inclusive === 0) {
        for (let i = 0; i < this.sm.items.length; i++) {
          const e = this.sm.items[i]
          const sub = parseFloat(e.quantity) * parseFloat(e.cost)
          const discount = sub * parseFloat(e.discount / 100)
          total += sub - discount
        }
      } else {
        for (let i = 0; i < this.sm.items.length; i++) {
          const e = this.sm.items[i]
          const sub = parseFloat(e.quantity) * parseFloat(e.cost)
          const discount = sub * parseFloat(e.discount / 100)
          const discounted = sub - discount
          const tax = parseFloat(e.tax) / 100
          total += discounted - (discounted * tax)
        }
      }

      return total
    },
    vats () {
      const vt = []

      if (this.sm.items.length > 0) {
        for (let i = 0; i < this.sm.items.length; i++) {
          const e = this.sm.items[i]
          const found = vt.find(vat => vat.rate === e.tax)

          if (found) {
            found.value += (e.tax / 100) * (parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100)))
          } else {
            vt.push({
              rate: e.tax,
              value: (e.tax / 100) * (parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100)))
            })
          }
        }
      }

      return vt
    },
    total () {
      let total = 0

      if (this.sm.items.length > 0) {
        for (let i = 0; i < this.sm.items.length; i++) {
          const e = this.sm.items[i]
          total += parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100))
        }
      }

      if (!this.sm.is_inclusive) {
        const vats = this.vats

        for (let i = 0; i < vats.length; i++) {
          const vat = vats[i]
          total += parseFloat(vat.value)
        }
      }

      return total
    }
  },
  watch: {
    updateChild: function () {
      if (this.updateChild) {
        this.$emit('update:updateChild', false)
        this.$router.push({ name: 'shipment-view', params: { id: this.$route.params.id, sid: this.$route.params.sid } })
      }
    }
  },
  methods: {
    // handlers
    cancel () {
      this.$router.push({ name: 'shipment-list' })
    },
    submit () {
      this.$refs.sm.validate()
        .then(async () => {
          try {
            this.loading = true
            const sm = this.$_.cloneDeep(this.sm)
            this.$Sanitize(sm)

            for (let i = 0; i < sm.items.length; i++) {
              const item = sm.items[i]
              delete item.variant
              delete item.product
              delete item.order_item
              delete item.created_at
              delete item.updated_at
            }

            delete sm.bill_to
            delete sm.ship_to
            delete sm.ship_from
            delete sm.created_at
            delete sm.updated_at
            delete sm.payment_term

            sm.units = this.totalUnits
            sm.subtotal = this.subtotal
            sm.vats = JSON.stringify(this.vats)
            sm.total = this.total

            if (this.mode === 'create') {
              const ssService = new SalesShipmentService(this.$route.params.id)
              const result = await ssService.create(sm)
              this.$emit('update:update', true)
              this.$message.success('Shipment was successfully created')
              this.$router.push({ name: 'shipment-view', params: { id: this.$route.params.id, sid: result.data.id } })
            } else {
              const ssService = new SalesShipmentService(this.$route.params.id, this.$route.params.sid)
              await ssService.update(sm)
              this.$emit('update:update', true)
              this.$message.success('Shipment was successfully updated')
              this.$router.push({ name: 'shipment-view', params: { id: this.$route.params.id, sid: this.$route.params.sid } })
            }
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },

    // Service fetch
    async getSalesOrder () {
      try {
        const soService = new SalesOrderService(this.$route.params.id)
        const result = await soService.get()
        this.so_number = result.data.so_number
        this.sm.customer_id = result.data.customer_id
        this.sm.bill_to_id = result.data.bill_to_id
        this.sm.bill_to = result.data.bill_to
        this.sm.ship_to_id = result.data.ship_to_id
        this.sm.ship_to = result.data.ship_to
        this.sm.ship_from_id = result.data.ship_from_id
        this.sm.ship_from = result.data.ship_from
        this.sm.is_inclusive = result.data.is_inclusive

        for (let i = 0; i < result.data.items.length; i++) {
          const item = result.data.items[i]
          this.sm.items.push({
            sales_order_item_id: item.id,
            variant_id: item.variant_id,
            product: item.product,
            variant: item.variant,
            quantity: item.quantity - item.packed,
            cost: item.cost,
            order_item: { packed: item.packed || 0 },
            total_quantity: item.quantity,
            discount: item.discount,
            tax: item.tax
          })
        }
      } catch (error) {
        this.$Error(error)
      }
    },
    async getSalesOrderNo () {
      try {
        const soService = new SalesOrderService(this.$route.params.id)
        const result = await soService.getSOandStatus()
        this.so_number = result.data.so_number
      } catch (error) {
        this.$Error(error)
      }
    },
    async getShipment () {
      try {
        this.loading = true
        const ssService = new SalesShipmentService(this.$route.params.id, this.$route.params.sid)
        const result = await ssService.get()
        this.sm = result.data
        this.sm.packed = this.$DateISOFormat(this.sm.packed)

        for (let i = 0; i < this.sm.items.length; i++) {
          const item = this.sm.items[i]
          item.order_item.packed = parseFloat(item.order_item.packed) - parseFloat(item.quantity)
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.mode = this.$route.meta.mode

    if (this.mode === 'create') {
      await this.getSalesOrder()
      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Sales Order', route: '' },
        { title: 'Order' },
        { title: this.so_number },
        { title: 'Shipments' },
        { title: 'New' }
      ])
    } else {
      await this.getSalesOrderNo()
      await this.getShipment()
      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Sales Order', route: '' },
        { title: 'Order' },
        { title: this.so_number },
        { title: 'Shipments' },
        { title: this.title },
        { title: 'Edit' }
      ])
    }
  }
}
</script>
